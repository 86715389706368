.order-container,
.order-single-container {
  width: 90%;
  margin: 0 auto;
  @include breakpoint(md) {
    width: 60%;
  }
  .order-inner-container {
    .order-left-section {
      width: 100%;
      background-color: #efefef;
      align-self: flex-start;
      @include breakpoint(md) {
        width: 45%;
      }
      @include breakpoint(lg) {
        width: 35%;
      }
      .order-section-container {
        width: 100%;
      }
    }
    .order-right-section {
      width: 100%;
      @include breakpoint(md) {
        width: 55%;
      }
      @include breakpoint(lg) {
        width: 65%;
      }
      .order-section-container {
        width: 100%;
      }
      .tooltip-container {
        position: absolute;
        right: 10px;
        top: 12.5px;
        margin: 0;
      }
    }
    .header-title {
      font-size: 20px;
      font-family: 'SofiaPro', sans-serif;
      font-weight: bold;
      letter-spacing: 0.25px;
      color: #202224;
      background-color: #efefef;
    }
    .w-100 {
      width: 100%;
    }
    .collapse-btn {
      position: absolute;
      left: unset;
      top: 25px;
      right: 5%;
      cursor: pointer;
      font-size: 18px;
      color: #016f66;
      @include breakpoint(md) {
        top: 59px;
        left: 37%;
        rotate: 0deg;
        right: unset;
      }
      @include breakpoint(lg) {
        top: 59px;
        left: 29.5%;
        rotate: 0deg;
        right: unset;
      }
    }
    .toggle-btn {
      @include breakpoint(md) {
        top: 17px;
        left: 39px;
      }
    }
    .md-position-sticky {
      @include breakpoint(md) {
        position: -webkit-sticky;
        position: sticky;
        top: -40px;
        height: 100vh;
      }
    }
  }

  .order-page-title {
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: $black;
    letter-spacing: 0.25px;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-left: 11%;
    @include breakpoint(md) {
      font-size: 34px;
      font-weight: 400;
      margin-left: auto;
      text-align: left;
    }
  }
  .single-order-page-title {
    margin-left: 11%;
    font-size: 28px;
    @include breakpoint(md) {
      margin-left: 0%;
    }
  }
  .question-section-btn {
    .question-btn {
      color: $black;
      background-color: transparent;
      font-family: 'SofiaPro', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      padding: 0;
      border-radius: 20px;
      outline: none;
      border: 1px solid $black;
      width: 30px;
      height: 30px;
      &.active {
        background: $gradient-background;
        color: #e5e5e5;
        border: none;
      }
    }
    .section-name {
      font-family: 'SofiaPro', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      @include breakpoint(md) {
        font-size: 16px;
      }
      &.active {
        font-family: 'SofiaPro', sans-serif;
        font-weight: bold;
        font-size: 14px;
        @include breakpoint(md) {
          font-size: 16px;
        }
      }
    }
  }
  .question-radio-btn {
    padding: 8px 12px 10px 12px;
    font-style: normal;
    color: $black;
    font-size: 14px;
    @include breakpoint(md) {
      padding: 20px 12px 30px 12px;
      font-size: 16px;
    }
    label {
      cursor: pointer;
      outline: none;
    }
    .radio-input {
      font-family: Gilroy, sans-serif;
      font-weight: bold;
    }
    .question-desc {
      font-family: 'SofiaPro', sans-serif;
      font-weight: normal;
      letter-spacing: 0.5px;
      font-size: 13px;
      @include breakpoint(md) {
        font-size: 14px;
      }
    }
  }
  .order-right-section {
    .order-section-container {
      width: 100%;
      @include breakpoint(md) {
        width: 80%;
        margin: 0 auto;
      }
      .tooltip-btn {
        .order-radio-btn {
          padding-right: 0px;
          margin-left: 0;
          @include breakpoint(md) {
            padding-right: 30px;
            margin-left: 15px;
          }
        }
      }
      .order-radio-btn {
        border: 1px solid #dcdcdc;
        margin-left: 0px;
        padding: 8px 10px;
        border-radius: 6px;
        color: #000000de;
        font-size: 12px;
        font-weight: 700;
      }
      .order-radio-btn-1 {
        min-width: 103px;
      }
      .order-radio-btn-2 {
        min-width: 124px;
      }
      .radio-active {
        color: #016f66;
        .tooltip-button {
          color: #016f66;
        }
      }
      label:not(.ah-checkbox-label) {
        align-items: center;
        display: flex;
        cursor: pointer;
      }
      input[type='radio'],
      input[type='checkbox']:not(.p-checkbox-input) {
        margin-right: 7px !important;
        width: 20px;
        height: 20px;
      }
      .radio-btn-disabled {
        label {
          color: grey;
        }
      }
    }
  }
  .input-conatainer {
    width: 100%;
    margin: 20px 0 15px;
    @include breakpoint(md) {
      margin: 40px 0 15px;
    }
  }
  .question-type-container {
    margin-top: 20px;
    @include breakpoint(md) {
      margin-top: 30px;
    }
  }
  .clinical-question-container {
    .question-label {
      font-family: 'SofiaPro', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.25px;
      color: $text-primary-color;
      display: inline-block;
      @include breakpoint(md) {
        font-size: 20px;
      }
    }
    .input-conatainer {
      width: 100%;
      margin: 6px 0;
      @include breakpoint(md) {
        margin: 6px 0;
      }
    }
    .text-count {
      font-size: 10px;
      margin-bottom: 15px;
      @include breakpoint(md) {
        margin-bottom: 30px;
      }
    }
    .question-name {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 0.25px;
      color: $primary-color;
      .question-check {
        width: 24px;
        height: 24px;
        background-color: #8db989;
        text-align: center;
        border-radius: 50%;
        font-size: 15px;
        padding: 4px;
        box-sizing: border-box;
      }
    }
    .change-btn {
      border: none;
      background: transparent;
      outline: none;
      cursor: pointer;
      font-size: 16px;
      color: $black;
    }
    .send-request-btn {
      padding: 17px;
      border-radius: 10px;
      font-size: 16px;
      line-height: 20px;
      width: 100%;
      max-width: 100%;
      @include breakpoint(md) {
        width: 48%;
        max-width: 48%;
      }
    }
    .user-details {
      border-radius: 4px;
      word-break: break-word;
      .user-details-value {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.87);
      }
      .user-details-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .w-50 {
      width: 100%;
      @include breakpoint(lg) {
        width: 50%;
      }
    }
    .input-label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
    }
    .input-conatainer {
      .inputText {
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        padding: 12px 16px;
        box-sizing: border-box;
      }
    }
    .react-datepicker-wrapper {
      input {
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        padding: 12px 16px;
        box-sizing: border-box;
        width: 100%;
        @include breakpoint(md) {
          width: 300px;
        }
      }
    }
  }
  .study-protocol-text {
    padding-bottom: 10px;
    a {
      color: $black;
    }
  }
  .picot-container {
    border: 2px solid #efefef;
    padding: 24px 16px 4px 16px;
    border-radius: 12px;
    margin-bottom: 25px;
    .text-count {
      margin-bottom: 20px;
    }
  }
  .picot-switch {
    background: $gray-color-1;
    padding: 10px;
    border-radius: 10px;
    .picot-span {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      padding-top: 3px;
    }
    .react-switch-handle {
      box-shadow: none !important;
      border: 1px solid $black !important;
    }
    .react-switch-bg {
      border: 1px solid $black;
    }
  }
  .order-header-container {
    position: relative;
    .order-back-button {
      position: absolute;
      top: 4px;
      left: 0;
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 24px;
      cursor: pointer;
      padding-left: 0;
      color: $black;
      @include breakpoint(md) {
        top: 6px;
      }
    }
  }
  .d-mobile {
    display: block;
    @include breakpoint(md) {
      display: none;
    }
  }
  .d-desktop {
    display: none;
    @include breakpoint(md) {
      display: block;
    }
  }
  input:focus {
    border-color: $primary-color;
  }
  textarea:focus-visible {
    outline: none;
    border-color: $primary-color;
  }
}

.order-single-container {
  width: 94%;
  margin: 0 auto;
  @include breakpoint(md) {
    width: 80%;
  }
}

.tooltip-container {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  margin-right: 10px;
}

.tooltip-box {
  position: absolute;
  color: $white;
  padding: 5px;
  top: calc(100% + 5px);
  display: none;
  background: rgba(97, 97, 97, 0.9);
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  z-index: 10;
  right: -5px;
  width: 205px;
  a {
    color: $white;
  }
}

.tooltip-box.visible {
  display: block;
}

.tooltip-arrow {
  position: absolute;
  top: -10px;
  right: 4%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(97, 97, 97, 0.9) transparent;
}

.edit-info-text {
  color: $primary-color;
  font-weight: 600;
  font-size: 14px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.edit-info-container {
  background-color: #fbfbfc;
  border-radius: 4px;
  padding: 16px;
  .info-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
  }
  .info-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $black;
  }
}
.institute-body-div {
  width: 100%;
  height: auto;
}
.institute-queries-container {
  width: 48%;
  position: absolute;
  margin-top: 2px;
  padding-bottom: 20px;

  .institute-list-div {
    background: $white;
    border-radius: 16px;
    z-index: 9;
    position: relative;
    box-shadow: 0px -1px 5px $gray-color-3;
  }
}
.display-institute-list {
  padding: 5px !important;
}
.ui-List {
  width: 100%;
  height: auto;
  padding: 2px;
  margin: 0;
  max-height: 350px;
  overflow: auto;
}
.send-request {
  padding: 17px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  max-width: 100%;
  @include breakpoint(md) {
    width: 48%;
    max-width: 48%;
  }
}
.advanced-settings-div {
  width: 100%;
}
.advaced-settings-div {
  width: 100%;
  .no-file {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    border: none;
    outline: none;
  }
  .loader-btn-view {
    width: 100%;
    @include breakpoint(md) {
      width: 50%;
    }
  }
  .document-format-text {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
  .document-error {
    color: $error-color;
    font-size: 14px;
    svg {
      font-size: 20px;
    }
  }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .inputfile-label {
    color: $white;
    background-color: $primary-color;
    display: inline-block;
    cursor: pointer;
    border-radius: 10px;
    padding: 11px 20px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    @include breakpoint(md) {
      width: 98px;
    }
  }
  .inputfile-label,
  .inputfile-label:hover {
    background-color: $primary-color;
  }
  .btn-primary {
    width: 100%;
    background: $primary-color;
    padding: 11px 20px;
    text-align: center;
    @include breakpoint(md) {
      width: 98px;
    }
  }
  .disbaled-document-upload {
    opacity: 0.5;
    label {
      cursor: not-allowed;
    }
  }
  .document-disabled-text {
    font-size: 14px;
    .login-btn {
      background-color: transparent;
      border: none;
      font-size: 14px;
      padding: 0;
      cursor: pointer;
      color: $primary-color;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
.right-arrow {
  width: 36px;
  height: 36px;
  top: 24px;
  right: 16px;
  button {
    font-size: 24px;
    color: $black;
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
.advanced-setting-title {
  line-height: 34px;
  width: fit-content;
  font-family: 'SofiaPro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.25px;
  color: $black;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.tooltip {
  position: relative;
  margin: 7px;
}

.tooltiptext {
  font-size: 10px;
  line-height: 14px;
  visibility: hidden;
  width: 10vw;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 30px;
  left: 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.rotate-button {
  background-color: transparent;
  outline: none;
  button {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(900deg);
    transform: rotate(90deg);
  }
}

.heading-text {
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  font-size: 14px;
}

.sub-heading-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}

.display-advaced-settings-block {
  width: 100%;
  margin-bottom: 5%;
}
.button-container {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
}
.advanced-setting-checkbox {
  width: 30%;
  display: inline;
}
.advanced-settings-button {
  letter-spacing: 0.25px;
}
.toolTip-div {
  letter-spacing: 0.25px;
  position: relative;
  display: inline-block;
}
.container-tooltip-box {
  position: absolute;
  left: 80%;
  font-size: 14px;
  top: -7px;
  .tooltip-box {
    right: unset;
    left: 0px;
  }
  .tooltip-arrow {
    right: unset;
    left: 3px;
  }
}
.set-div-width {
  width: 150px;
  @include breakpoint(sm) {
    width: 380px;
  }
  @include breakpoint(md) {
    width: 380px;
  }
  @include breakpoint(lg) {
    width: 400px;
  }
  @include breakpoint(xl) {
    width: 400px;
  }
}
input[type='radio'],
input[type='checkbox'] {
  accent-color: $primary-color;
}
input[id='radio-checkbox-id'] {
  accent-color: $primary-color;
  -ms-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  cursor: pointer;
}
.tooltip-button {
  cursor: pointer;
}

.questionType {
  padding-left: 0px;
  width: 100%;
}
.questionType-drop-down {
  left: 0px;
  padding: 0px;
  padding-top: 10px;
  position: relative;
  opacity: 0.99;
  z-index: 5;
  top: -5px;
}
.hover-option {
  width: auto;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.7;
  }
}
.questionTypeDiv {
  width: 100%;
}
.questionType-dropdown-select {
  box-shadow: 0px -1px 5px $gray-color-3;
  border: 0px solid $white;
  border-radius: 11px;
  width: 100%;
  background-color: $white;
  margin: 0px;
  padding-block-start: 0%;
  position: absolute;
}
.add-margin {
  margin-top: 18px;
}
.w-50 {
  width: 100%;
  @include breakpoint(lg) {
    width: 48%;
  }
}

.data-source-container {
  .data-source {
    border: 0.4px solid rgba(0, 0, 0, 0.5);
    padding: 15px 8px;
    border-radius: 6px;
    background-color: #ffffff;
  }
  .input-no-validate {
    border: 1px solid #ff0000;
    border-radius: 6px;
  }
  button {
    border: none;
    background-color: transparent;
    color: $primary-color;
    font-size: 20px;
    right: 0;
    top: 13px;
  }
  .data-source-list {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 -1px 5px #797a7c;
    padding: 0px 8px;
    width: 100%;
    z-index: 1;
    ul {
      margin: 0;
    }
  }
  .source-group-container {
    .source-group-name {
      background: $bg-secondary;
      font-size: 15px;
    }
    .source-group-options {
      font-size: 13px;
    }
  }
}

.placeholder-color {
  color: $placeholder-color;
}

.disabled-data {
  .data-source,
  button {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

.organization-label {
  font-size: 12px;
  font-family: 'SofiaPro', sans-serif;
  color: $black;
  margin-top: 30px;
  color: #242426;
}

.sort-btn-container {
  width: 100%;
  @include breakpoint(lg) {
    width: auto;
  }
}
.urgency-container {
  input[type='radio'] {
    cursor: pointer;
    width: 22px;
    height: 22px;
    margin-top: 0;
  }
  .radio-input {
    cursor: pointer;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    padding: 12px;
    &:first-child {
      margin-right: 0px;
      margin-bottom: 15px;
      @include breakpoint(lg) {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
    label {
      cursor: pointer;
      font-size: 12px;
      span {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}

.phi-modal-overlay {
  background: rgba(20, 20, 20, 0.5) !important;
  .modal-container {
    .phi-modal {
      border-radius: 8px;
      width: 100% !important;
      @include breakpoint(md) {
        width: 60% !important;
      }
      @include breakpoint(lg) {
        width: 34% !important;
      }
      .close-btn {
        color: $gray-btn-color;
      }
    }
    .plain-btn {
      border: 1px solid $primary-color;
      border-radius: 10px;
      width: 100%;
      padding: 7px 17px;
      height: 45px;
      @include breakpoint(md) {
        width: auto;
      }
    }
    .btn-primary {
      width: 100%;
      @include breakpoint(md) {
        width: auto;
      }
    }
  }
}
.text-decoration-underline {
  text-decoration: underline;
}

.ai-worflow-container {
  font-size: 16px;
  color: #343541;
  line-height: 28px;
  .open-ai-text {
    color: #444654;
    font-size: 12px;
    line-height: normal;
  }
  .order-logo-container {
    img {
      width: 54px;
      height: auto;
    }
  }
  .order-avtar {
    width: 54px;
    height: 54px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: $white;
    font-size: 16px;
    box-sizing: content-box;
    border-radius: 4px;
    margin-bottom: 10px;
    max-width: 100%;
  }
  .nav-img {
    background-color: #4169d4;
    width: 50px;
    height: 50px;
    padding: 2px;
  }
  .green-bg {
    background-color: #d8eeee;
    a {
      color: #343541;
    }
    .text-upercase {
      text-transform: capitalize;
    }
  }
  .w-80 {
    width: 100%;
    @include breakpoint(md) {
      width: 80%;
    }
  }
  .w-10 {
    width: 100%;
    @include breakpoint(md) {
      width: 13%;
    }
  }
  // table {
  //   border: 1px solid #016f66;
  //   border-collapse: collapse;
  //   text-align: left;
  //   tr {
  //     border-bottom: 1px solid #016f66;
  //     &:last-child {
  //       border-bottom: none;
  //     }
  //     th {
  //       background-color: #016f66;
  //       color: $white;
  //       padding: 5px;
  //     }
  //     td {
  //       padding: 5px;
  //       &:first-child {
  //         padding-left: 10px;
  //       }
  //       &:last-child {
  //         padding-right: 10px;
  //       }
  //     }
  //   }
  // }
  .w-40 {
    width: 100%;
    @include breakpoint(md) {
      width: 40%;
    }
  }
  .w-60 {
    width: 100%;
    @include breakpoint(md) {
      width: 60%;
    }
  }
  .w-90 {
    width: 100%;
    @include breakpoint(md) {
      width: 90%;
    }
  }
  img {
    max-width: 100%;
  }
  .demographicsImg {
    cursor: pointer;
    transition: width 0s linear 0.33s;
  }
  .showbigImg {
    transition-delay: 0s;
  }
  .pg-top-border {
    border-top: 1px solid #cbe1e1;
    .blue-btn {
      width: 100%;
      background-color: transparent;
      span {
        text-decoration: underline;
      }
    }
  }
  .blue-btn {
    font-size: 15px;
    padding: 14px 15px;
    color: #00000099;
    &:hover:not(.active-bg):not(.btn-disabled) {
      color: $white;
      span {
        color: $white;
      }
      svg {
        path {
          fill: var(--color-white);
        }
      }
    }
    svg {
      margin-right: 10px;
      font-size: 22px;
    }
  }
  .green-border {
    border: 1px solid #d8eeee;
  }
  .input-conatainer {
    .input-textarea {
      padding: 10px 30px 20px 20px;
      border: 1px solid #d1d5db;
      box-shadow: 0px 0px 15px 0px #0000001a;
      font-size: 18px;
      height: 260px;
      @include breakpoint(md) {
        padding: 35px 30px 35px 30px;
        height: auto;
      }
    }
  }
  .suggestion-tooltip {
    top: 40px;
    left: 0px;
    width: 189px;
    color: #fff;
    padding: 5px;
    background: rgba(97, 97, 97, 0.9);
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    &:after {
      content: '';
      position: absolute;
      top: -10px;
      left: 4%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent rgba(97, 97, 97, 0.9) transparent;
    }
  }
  .textarea-container {
    position: relative;
  }
  .send-btn {
    position: absolute;
    top: 43%;
    right: 3%;
    cursor: pointer;
    color: #afafb2;
    font-size: 20px;
    &:hover {
      color: $primary-color;
    }
  }
  .active-bg {
    background: $gradient-background;
    color: $white;
  }
  .w-0 {
    width: 0;
    transition: width 1s linear;
  }
  .cancel-btn {
    background-color: transparent;
    color: #00000099;
    border: 1px solid #00000099;
    &:hover {
      background: $gradient-background;
      color: $white;
    }
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
  .picot-input {
    .input-conatainer {
      .inputText {
        width: 100%;
        padding: 15px 6px;
        margin: 0 !important;
      }
    }
  }
  .delete-rows-icon {
    cursor: pointer;
    position: absolute;
    left: -24px;
    align-items: center;
    display: flex;
    svg {
      font-size: 20px;
      position: relative;
      bottom: 10px;
    }
    span {
      color: white;
      background-color: #1a1a1aa6;
      font-size: 12px;
      border-radius: 16px;
      padding: 0px 3px 2px;
      line-height: 21px;
      position: relative;
      bottom: 10px;
    }
  }
  .delete-text {
    left: -44px;
  }
  .parameter-form {
    border-top: none;
    .parameter-form-heading {
      font-size: 20px;
      font-weight: 600;
    }
    .input-heading-label {
      font-weight: 600;
      font-size: 16px;
    }
    // input[type='checkbox'] {
    //   accent-color: rgb(3, 213, 158);
    //   // color: $white;
    // }
  }
  .wrapper {
    .question-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
    .arrow {
      font-size: 20px;
      margin-left: 5px;
      margin-top: 3px;
    }
  }
  .arrow.active {
    rotate: 90deg;
  }
}

.ai-worflow-container .btn-disabled:hover span {
  color: #00000099;
}

.ReactModalPortal {
  .chatrwdModal {
    background-color: $white;
    opacity: 1;
    .modal-container {
      color: $black;
      position: relative;
      padding-top: 26vh;
      .chat-close-btn {
        position: absolute;
        top: 4vw;
        right: 4vw;
        background: $primary-color;
        border: none;
        outline: none;
        border-radius: 2px;
        padding: 5px 10px;
        cursor: pointer;
      }
      .modal-outer-container {
        width: 100%;
        margin: 0 auto;
        @include breakpoint(md) {
          width: 35%;
        }
        @include breakpoint(lg) {
          width: 30%;
        }
        @include breakpoint(xl) {
          width: 27%;
        }
        .header-text-chat {
          font-size: 20px;
        }
        .sub-header-text-chat {
          color: $text-secondary-color;
          font-size: 16px;
        }
        .chat-link {
          cursor: pointer;
          background-color: transparent;
          border: none;
          outline: none;
          color: #009688;
          font-size: 16px;
          padding: 0;
        }
      }
    }
  }
}
.table-ml-sm {
  margin-left: 15px;
  @include breakpoint(md) {
    margin-left: 0;
  }
}
.table-ml-md {
  margin-left: 35px;
  @include breakpoint(md) {
    margin-left: 0;
  }
}
.custom-rage-slider .multi-range-slider {
  .thumb {
    .caption {
      display: flex !important;
      top: 35px;
      pointer-events: none !important;

      .min-caption,
      .max-caption {
        border-radius: 0;
        background-color: $white;
        color: $black !important;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
        border-radius: 5px;
      }
    }
  }
}
.request-section-tooltip {
  position: relative;
  display: inline-block;

  .request-section-tooltiptext {
    visibility: hidden;
    color: $white;
    border-radius: 4px;
    font-weight: 500;
    padding: 5px;
    font-size: 12px;
    background: $custom-toolTip-bg;
    position: absolute;
    min-width: fit-content;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 5px;
    }
  }

  .support-info-top-tooltip {
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    @include breakpoint(xl) {
      width: 225px;
    }
    &:after {
      top: 100%;
      left: 35%;
      margin-left: -5px;
      border-color: $custom-toolTip-icon-bg transparent transparent transparent;
      @include breakpoint(xl) {
        left: 25%;
        margin-left: -2px;
      }
    }
  }

  .datasource-info-top-tooltip {
    bottom: 150%;
    left: 50%;
    margin-left: -50px;
    width: 150px;
    @include breakpoint(lg) {
      width: 150px;
    }
    @include breakpoint(xl) {
      width: 200px;
    }
    &:after {
      left: 50%;
      top: 100%;
      margin-left: -60px;
      border-color: $custom-toolTip-icon-bg transparent transparent transparent;
      @include breakpoint(lg) {
        left: 25%;
        margin-left: 5px;
      }
      @include breakpoint(xl) {
        left: 25%;
        margin-left: -8px;
      }
    }
  }

  .datasource-xsm-tooltipText {
    bottom: 150%;
    left: 100%;
    margin-left: -50px;
    width: 225px;
    @include breakpoint(sm) {
      width: 200px;
    }
    @include breakpoint(md) {
      width: 165px;
    }
    @include breakpoint(lg) {
      width: 155px;
    }
    @include breakpoint(xl) {
      width: 235px;
    }
    &:after {
      top: 100%;
      left: 50%;
      margin-left: -80px;
      @include breakpoint(sm) {
        margin-left: -68px;
      }
      @include breakpoint(md) {
        margin-left: -50px;
      }
      @include breakpoint(lg) {
        margin-left: -45px;
      }
      @include breakpoint(xl) {
        margin-left: -85px;
      }
    }
  }

  .datasource-sm-tooltipText {
    bottom: 150%;
    left: 100%;
    margin-left: -50px;
    width: 150px;
    @include breakpoint(sm) {
      width: 200px;
    }
    @include breakpoint(md) {
      width: fit-content;
    }
    @include breakpoint(lg) {
      width: fit-content;
    }
    @include breakpoint(xl) {
      width: 125px;
    }
    &:after {
      top: 100%;
      left: 50%;
      margin-left: -43px;
      @include breakpoint(sm) {
        margin-left: -68px;
      }
      @include breakpoint(md) {
        margin-left: -7px;
      }
      @include breakpoint(lg) {
        margin-left: -7px;
      }
      @include breakpoint(xl) {
        margin-left: -30px;
      }
    }
  }

  .datasource-responsive-tooltip {
    bottom: 150%;
    left: 100%;
    margin-left: -50px;
    width: fit-content;
    &:after {
      top: 100%;
      left: 50%;
      margin-left: -7px;
    }
  }

  &:hover {
    .request-section-tooltiptext {
      visibility: visible;
    }
  }

  .top-1 {
    top: 1.5px;
  }
}

.text-white {
  color: $white;
}

.left-section-wraper {
  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 24px;
    @include breakpoint(sm) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .header-container,
  .content-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    @include breakpoint(sm) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .content-wrapper {
    flex: 1;
    overflow: auto;
  }
  .header-container {
    padding-bottom: 24px;
  }
}

.chip-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
}

.chip {
  position: relative;
  margin-bottom: 5px;
  max-width: 100%;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
  white-space: nowrap;
  outline: 0px;
  text-decoration: none;
  padding: 0px;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid #0d3f3c;

  .chip-label {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 11px;
    padding-right: 11px;
    white-space: nowrap;
  }

  .chip-icon {
    -webkit-tap-highlight-color: transparent;
    font-size: 15px;
    cursor: pointer;
    margin: 2px 5px 0px -6px;
  }
}

.text-primary {
  color: $text-primary-color;
}

.text-underline {
  text-decoration: underline;
}

.flex-basis-100 {
  flex-basis: 100%;
}

#custom-textinput-container {
  .input-layout-1 {
    margin-right: 0 !important;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 13px 6px 12px;
    // border: 1px solid $border-input-primary;
    border-radius: 6px;
    height: 35px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
  }
  .input-1 {
    @include breakpoint(lg) {
      text-align: right;
      width: 74px;
    }
  }
  .text-input-2 {
    @include breakpoint(lg) {
      text-align: right;
      width: 100px;
    }
  }
  .input-2 {
    @include breakpoint(lg) {
      width: 144px;
    }
  }
  .input-conatainer {
    width: auto;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.custom-dropdown {
  .custom-dropdown-panel {
    width: 100%;
    background: $white;
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
      0px 15px 35px -5px rgba(17, 24, 38, 0.15), 0px 5px 15px 0px rgba(0, 0, 0, 0.08);
    position: absolute;
    z-index: 1;
    top: 25px;
    left: 0;
    box-sizing: border-box;
    padding: 5px;
    .options-wrapper {
      padding: 8px 6px;
    }
  }
  .dropdown-arrow-icon {
    position: absolute;
    top: 10px;
    right: 5px;
  }
}

.selected {
  color: white !important;
  background-color: #016f66;
  border-radius: 4px;
  padding-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.radio-input-wrapper {
  label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.28px;
  }
  input {
    margin-top: 0;
    margin-left: 0;
  }
}

.iframe-embed-wrapper {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 2em;

  &.iframe-embed-responsive-16by9 {
    padding-bottom: 56.25%;
  }
  .iframe-embed {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

.ah-alert {
  border-radius: 8px;
  padding: 10px 12px;

  &.ah-alert-error {
    background: $error-bg;
    border: 1px solid $error-border;
  }

  &.list-bullet {
    list-style-type: disc;
    list-style-position: inside;
  }

  .list-spacing:not(:last-child) {
    margin-bottom: 4px;
  }
}
