@import '@primer/css/utilities/index.scss';

.aiWorflowContainer {
  textarea {
    padding: 10px 30px 20px 20px;
    border: 1px solid #d1d5db;
    box-shadow: 0px 0px 15px 0px #0000001a;
    font-size: 18px;
    height: 260px;
    &:focus {
      outline: none;
      border: 1px solid #d1d5db;
    }
    @include breakpoint(md) {
      padding: 35px 30px 35px 30px;
      height: auto;
    }
  }
}

.frameContainer {
  display: block;
  width: 100%;
  border: 1px solid #d8eeee;
  .framelogo {
    height: auto;
    width: 54px;
  }
  .frameBody {
    background-color: #d8eeee;
    padding: 16px;
    @include breakpoint(lg) {
      padding: 30px 40px;
    }
  }
}

.studyFormatFormContainer {
  input {
    margin: 0;
    padding: 5px !important;
    width: 100% !important;
  }
}

.aiButton {
  color: #00000099;
  font-size: 15px;
  padding: 12px;
  svg {
    font-size: 22px;
    padding-right: 5px;
  }
  &:hover {
    color: #fff;
  }
  &.activeBg {
    background: linear-gradient(85.92deg, #0d3f3c, #1e6763);
    color: #fff;
  }
}

.frameFooter {
  padding: 16px 8px;
  border: 1px solid #d8eeee;
}

.orderAvtar {
  img {
    height: auto;
    width: 30px;
  }
}
.navImg {
  background-color: #4169d4;
  height: 30px;
  width: 30px;
  padding: 2px;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
}
