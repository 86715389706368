.search-input {
  background: $gray-color-2;
  border-radius: 16px;
  color: $text-primary-color;
  width: 100%;
  input {
    border-color: transparent;
    width: 96%;
    outline: none;
    background-color: transparent;
    padding: 15px 13px 14px 0;
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $text-primary-color;
    @include breakpoint(md) {
      padding: 19px;
    }
    ::placeholder {
      color: $placeholder-color;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: $placeholder-color;
    }

    ::-ms-input-placeholder {
      color: $placeholder-color;
    }
  }
  .input-conatainer {
    margin: 0;
    width: 75%;
    @include breakpoint(md) {
      width: 80%;
    }
  }
  .search-button {
    width: 11%;
    @include breakpoint(md) {
      width: 20%;
    }
    .search-icon-button {
      width: 100%;
      background-color: transparent;
      padding: 20px 5px 15px 13px;
      border-radius: 16px;
      border: none;
      color: $gray-color-3;
      font-family: 'SofiaPro', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      cursor: pointer;
      @include breakpoint(lg) {
        background-color: $primary-color;
        color: $white;
        font-size: 14px;
        padding: 20px;
      }
      span {
        font-size: 14px;
        display: inline-block;
      }
    }
  }
}

.disable-search {
  opacity: 0.6;
  input,
  button {
    cursor: pointer;
  }
}

.search-type-section {
  .search-type {
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.25px;
    color: $primary-color;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  li, .suggestion-title {
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $text-primary-color;
    mix-blend-mode: normal;
    opacity: 0.5;
    cursor: pointer;
    svg {
      font-size: 17px;
    }
    span {
      display: inline-block;
    }
  }
  .suggestion-title {
    cursor: default;
  }
}

.close-btn-container {
  position: absolute;
  right: -20px;
  top: -20px;
  @include breakpoint(md) {
    position: relative;
  }
  .close-btn {
    padding: 5px 20px;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    svg {
      width: 30px;
      height: 30px;
      color: $gray-color-3;
    }
  }
}

.search-view, .search-view-container {
  position: relative;
  z-index: 9;
  .clear-btn-container {
    padding-right: 0px;
  }
  .d-flex-toggle {
    background-color: #f8f8fa;
    padding-right: 5px !important;
    border-radius: 16px;
    color: #131212;
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
    width: auto;
    @include breakpoint(lg) {
      width: 20%;
    }
    @include breakpoint(xl) {
      width: 16%;
    }
    .picot-span {
      line-height: 18px;
      position: relative;
      max-width: 80px;
      min-width: 80px;
    }
    .tooltip-container {
      position: absolute;
      bottom: -11%;
      left: 52%;
      @include breakpoint(md) {
        bottom: -11%;
        left: 52%;
      }
      .tooltip-button {
        font-size: 18px;
        cursor: pointer;
      }
      .tooltip-box {
        width: 160px;
        top: calc(100% + 2px);
        @include breakpoint(md) {
          top: calc(100% + 5px);
        }
      }
      .tooltip-arrow {
        right: 5%;
        @include breakpoint(md) {
          right: 4%;
        }
      }
    }
  }
}
.search-queries-container {
  width: 100%;
  text-align: left;
  margin-top: -1px;
  z-index: 3;
  cursor: default;
  position: absolute;
  margin-top: 2px;
  .search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
  .search-queries {
    background: #fff;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 16px;
    padding-bottom: 4px;
    overflow: hidden;
    z-index: 9;
    position: relative;
    width: 100%;
    box-shadow: 0px -1px 5px $gray-color-3;
    @include breakpoint(lg) {
      width: 62%;
    }
    @include breakpoint(xl) {
      width: 66%;
    }
  }
  .all-search-terms {
    height: 560px;
    overflow-y: auto;
  }
  .xtSCL {
    margin: 0 20px 0 14px;
    padding-bottom: 4px;
  }
}
.search-query {
  font-weight: 700;
}
.dropdown-select {
  width: 100%;
  height: auto;
  background-color: #fff;
  border: 2px solid rgb(252, 249, 249);
  box-shadow: 1px 2.5px 2px 2px rgb(167, 166, 166);
  border-radius: 8px;
  margin: 0;
}
.option-list {
  background-color: #fff;
  width: 94%;
  height: auto;
  font-weight: 400;
  margin-left: 0px;
  color: black;
  font-family: 'SofiaPro', sans-serif, Arial, Helvetica;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 10px;
  font-size: 14px;

  &:hover {
    background-color: #d8eeee;
  }
  &:active {
    background-color: #d8eeee;
  }
}
.active-sort {
  background-color: #d8eeee;
}
ul {
  list-style: none;
  padding-inline-start: 0%;
  width: 100%;
  padding-block-end: 0%;
  text-align: left;
}
.drop-down {
  width: 150px;
  height: auto;
  position: absolute;
  z-index: 5;
  top: 55px;
  right: 5px;
  left: uset;
  @include breakpoint(md) {
    right: 0;
    left: unset;
    top: 50px;
  }
}

.empty-div {
  height: 0;
}

.modal-container {
  .search-modal {
    background-color: $white;
    width: 100%;
    margin: 0 auto;
    color: $black;
    text-align: left;
    position: relative;
    height: 60vh;
    @include breakpoint(md) {
      width: 49.5%;
    }
    .close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      border: none;
      font-size: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
    }
    ul {
      height: 100%;
      overflow-y: auto;
      margin: 0;
      li {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
.w-100 {
  width: 100%;
}
