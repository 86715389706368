.custom-rage-slider {
  .multi-range-slider {
    padding-left: 0;
    padding-right: 0;
    .bar-left,
    .bar-inner,
    .bar-right {
      box-shadow: none;
    }
    .bar-inner {
      border: none;
    }
    .thumb {
      &:before {
        box-shadow: none;
        border-color: rgb(193, 193, 193);
      }
      .caption {
        * {
          background-color: #0d3f3c;
          box-shadow: none;
        }
      }
    }
  }
  &.rm-left-radius {
    .bar-left {
      border-radius: 0;
    }
  }
  &.rm-right-radius {
    .bar-right {
      border-radius: 0;
    }
  }
  .multi-range-slider.disabled .thumb::before {
    box-shadow: none;
  }
}
.slider-part {
  background-color: var(--bg-inactive-gray);
  padding: 4px 0px;
  opacity: 0.5;
  &.slider-left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &.slider-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
