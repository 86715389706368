.single-prognostorm {
  font-family: 'SofiaPro', sans-serif;
  font-style: normal;
  position: relative;
  color: $text-primary-color;
  font-size: 13px;
  line-height: 20px;
  border: 2px solid #efefef;
  margin: 16px 0px;
  padding: 24px 16px;
  border-radius: 12px;
  &:first-child {
    margin-top: 0;
  }
  .details {
    width: calc(100% - 36px);
  }
  .title {
    font-size: 18px;
    line-height: 24px;
    color: $primary-color;
    font-weight: normal;
  }
  .date {
    mix-blend-mode: normal;
    opacity: 0.6;
  }
  .tag {
    display: inline-block;
    border-radius: 4px;
    margin-right: 15px;
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    position: relative;
    color: $text-primary-color;
    font-size: 13px;
    line-height: 20px;
    border: none;
    font-weight: 600;
    span {
      font-weight: 400;
    }
  }
  .specialty-tag {
    background-color: #d8e3ee;
    cursor: pointer;
  }
  .status {
    background-color: #cccccc;
    cursor: pointer;
  }
  .data-source-name {
    background-color: $tag-secondary-color;
  }
  .data-source-tag {
    background-color: rgba(216, 238, 238, 1);
    cursor: pointer;
    .tooltip-boxs {
      position: absolute;
      color: $white;
      padding: 5px;
      bottom: 34px;
      display: none;
      background: rgba(97, 97, 97, 0.9);
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      z-index: 10;
      left: -12px;
      width: 275px;
      line-height: 17px;
      @include breakpoint(md) {
        left: 70px;
        bottom: -20px;
      }
      a {
        color: $white;
        text-decoration: underline;
      }
    }
    .tooltip-i {
      bottom: 34px;
      left: 0;
      @include breakpoint(md) {
        left: 89px;
        bottom: -20px;
      }
    }
    .tooltip-boxs.visible {
      display: block;
    }
    .tooltip-boxs::before {
      content: '';
      position: absolute;
      border-top: 6px solid rgba(97, 97, 97, 0.9);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      left: 20px;
      bottom: -6px;
      @include breakpoint(md) {
        content: '';
        position: absolute;
        border-right: 6px solid rgba(97, 97, 97, 0.9);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        left: -12px;
        bottom: 30px;
      }
    }
  }
  .accordian-header {
    box-shadow: none !important;
    justify-content: start !important;
    padding: 0 !important;

    &.ai-search-accordian-header {
      justify-content: space-between !important;
    }
  }
  .hide-arrow.accordian-header {
    cursor: auto !important;
    .down-arrow {
      display: none;
    }
  }
  .down-arrow {
    background-color: #e6e6e6;
    border-radius: 8px !important;
    box-shadow: none;
    outline: none;
    width: 36px;
    height: 36px;
    top: 24px;
    right: 16px;
    button {
      font-size: 22px;
      color: $primary-color;
      cursor: pointer;
      border: none;
      background-color: transparent;
      -webkit-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      padding: 0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      outline: none;
    }
  }
  .header-is-expanded {
    button {
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
  .accordian-panel.is-expanded {
    overflow: visible !important;
    max-height: 100% !important;
    height: 100% !important;
  }
  .picot-lable {
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.25px;
    color: $black;
  }
  .picot-label-color {
    color: #016f66;
  }
  .picot-content {
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #262626;
    padding: 12px 16px;
    border: 1px solid #bebec8;
    border-radius: 8px;
  }
  .loader-btn {
    margin-right: 0;
    width: 100%;
    @include breakpoint(md) {
      margin-right: 1%;
      width: 30%;
    }
    @include breakpoint(lg) {
      margin-right: 1%;
      width: 26%;
    }
    .blue-btn {
      width: 100%;
      margin-right: 0;
      padding: 15px 20px;
      padding-left: 4px;
      padding-right: 4px;
      text-align: center;
    }
    .loader-btn-view {
      position: absolute;
      top: 5%;
      left: 25%;
      .bg-white {
        background-color: transparent !important;
      }
    }
  }
  .triageTag {
    background-color: #d8e3ee;
    .status-desc {
      min-width: 320px;
      top: -72px;
      right: -75px;
      left: unset;
      @include breakpoint(md) {
        right: unset;
        left: 0;
      }
    }
  }
  .provider-motivation-tag {
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      border: none;
      width: 100%;
      height: 13px;
      top: 28px;
      left: 0;
    }
    .provider-container {
      top: 35px;
      width: 160px;
      position: absolute;
      left: 0;
      background: #fff;
      border-radius: 8px;
      z-index: 9;
      box-shadow: 0px -1px 5px #797a7c;
      ul {
        margin: 0;
        li {
          &:hover {
            background-color: #d8eeee;
          }
          button {
            border: none;
            background-color: transparent;
            width: 100%;
            text-align: left;
            font-size: 15px;
            color: $text-primary-color;
          }
        }
      }
    }
  }

  &.order-ai-summary {
    background: var(--bg-gray-50);
  }
}
.accordian-container {
  box-shadow: none !important;
  margin-bottom: 180px;
}
.order-type {
  font-family: 'SofiaPro', sans-serif;
  color: $primary-color;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.25px;
  font-weight: 600;
  @include breakpoint(md) {
    font-weight: 400;
    font-size: 24px;
  }
}
.no-order-personal {
  .no-order-msg {
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    letter-spacing: 0.15px;
    color: $text-primary-color;
  }
  img {
    height: auto;
    width: 70%;
    @include breakpoint(md) {
      width: 30%;
    }
  }
  .no-order-text {
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.15px;
    color: $text-primary-color;
    line-height: 20px;
  }
  .gradient-btn {
    padding: 11px;
    border-radius: 10px;
    width: 100%;
    @include breakpoint(md) {
      width: 50%;
    }
  }
  .link {
    padding: 0;
    font-size: 14px;
    text-decoration: underline;
  }
}
.modal-video-close-btn {
  cursor: pointer;
}

.btn-container {
  bottom: 20px;
  right: 0px;
  .blue-btn {
    width: 100%;
    margin-right: 0;
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    @include breakpoint(md) {
      width: 30%;
      margin-right: 1%;
    }
    @include breakpoint(lg) {
      width: 26%;
      margin-right: 1%;
    }
  }
  .icon-button {
    align-items: center;
    svg {
      font-size: 20px;
    }
    span {
      margin-left: 8px;
    }
  }
}
.order-modal {
  .blue-btn {
    width: 100%;
    @include breakpoint(md) {
      width: 33%;
    }
  }
  .single-prognostorm {
    .loader-btn {
      width: 100%;
      @include breakpoint(md) {
        width: 33%;
      }
    }
  }
}
.dashboard-header-container {
  font-size: 15px;
  font-weight: normal;
  color: #000000de;
  text-align: center;
  .status-container {
    margin-right: 0px;
    @include breakpoint(md) {
      margin-right: 20px;
    }
    .header-btn {
      border: 1px solid #979797;
      border-radius: 8px;
      padding: 13px 0;
      background: transparent;
      margin-left: 0px;
      cursor: pointer;
      box-shadow: none;
      outline: none;
      box-sizing: border-box;
      color: $black;
      padding: 12px 8px;
      span {
        font-weight: bold;
        display: inline-block;
        margin-left: 5px;
      }
    }
  }
  .active-header {
    font-weight: bold;
    color: $black;
    .header-btn {
      border-color: $primary-color;
      background: $gradient-background;
      color: $white;
    }
  }
  .filter-btn-container {
    border: 1px solid $primary-color;
    border-radius: 10px;
    width: 100%;
    margin-right: 15px;
    font-size: 16px;
    background-color: transparent;
    outline: none;
    font-weight: 600;
    color: #031b1a;
    div {
      color: #031b1a;
    }
  }
  .filter-outer-container {
    .filter-close-btn {
      background-color: $primary-color;
      color: $white;
      position: fixed;
      top: 0;
      z-index: 999;
      left: 0;
      width: 40px;
      height: 40px;
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
    }
    .search-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
      display: block;
    }
    .grey-container {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: calc(100% - 40px);
      height: 100%;
      z-index: 99;
      background-color: #eeeeee;
      font-size: 14px;
      .filter-containter-1 {
        height: 100vh;
      }
      .filter-header {
        background-color: $white;
        font-size: 16px;
        box-sizing: border-box;
        max-height: 68px;
        min-height: 68px;
        button {
          background-color: transparent;
          outline: none;
          border: none;
          font-size: 16px;
          padding: 0;
          color: #031b1a;
          margin-top: 3px;
        }
      }
      .filter-btn {
        background-color: $white;
        width: 100%;
        border-radius: 8px;
        outline: none;
        border: none;
        font-size: 16px;
        text-align: left;
        color: #031b1a;
      }
      .filter-footer {
        background-color: $white;
        bottom: 0;
        right: 0;
        position: fixed;
        width: calc(100% - 40px);
      }
      .filter-view-btn {
        background-color: $primary-color;
        color: $white;
        width: 100%;
        border: none;
        border-radius: 10px;
        font-size: 16px;
        bottom: 0;
        &:disabled {
          opacity: 0.5;
        }
      }
      .filter-list-conatiner {
        overflow: auto;
        height: calc(100% - 148px);
        ul {
          margin-bottom: 70px;
        }
      }
      .filter-option {
        background-color: $white;
        border-radius: 10px;
        font-size: 16px;
        .checkbox-input {
          cursor: pointer;
          label {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #031b1a;
          }
        }
      }
    }
  }
}

.status-desc {
  top: -54px;
  left: unset;
  right: -75px;
  background-color: rgba(97, 97, 97, 0.9);
  border-radius: 4px;
  padding: 5px;
  line-height: 18px;
  color: $white;
  min-width: 200px;
  @include breakpoint(md) {
    left: 0;
    right: unset;
  }
  .status-link {
    font-size: 14px;
    font-weight: bold;
    color: $white !important;
  }
  a {
    color: $white !important;
  }
}
.top-arrow::before {
  content: '';
  position: absolute;
  border-left: 118px solid transparent;
  border-right: 118px solid transparent;
  border-top: 10px solid transparent;
  top: -9px;
  left: 0%;
}
.top-arrow::after {
  content: '';
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid rgba(97, 97, 97, 0.9);
  top: -9px;
  left: 4%;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ::i-block-chrome,
  .accordian-panel.is-expanded {
    max-height: 100% !important;
    overflow: visible !important;
    height: 100% !important;
  }
}

.modal-container {
  .delete-modal {
    width: 100% !important;
    @include breakpoint(md) {
      width: 30% !important;
    }
    .close-btn {
      right: 10px !important;
      top: 10px !important;
      padding: 0;
    }
    .cancel-sub-header {
      font-size: 16px;
      color: #262626;
    }
    .btn-primary {
      width: auto;
      height: 45px;
      padding: 7px 17px;
    }
  }
  .cancel-request-confirmation-modal {
    width: 100% !important;
    @include breakpoint(md) {
      width: 75% !important;
    }
    @include breakpoint(lg) {
      width: 45% !important;
    }
  }
  .bg-delete-success {
    background-color: $gray-btn-color !important;
  }
  .logo-img {
    img {
      width: 200px;
    }
  }
}
mark.highlight-red {
  background-color: transparent;
  font-weight: bold;
  color: $black;
}

.title {
  mark.highlight-red,
  .highlight {
    color: $primary-color;
    font-weight: bold;
  }
}
.specialty-shared {
  border: 1px solid #658583;
  padding: 13px 8px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.specialty-down-arrow {
  width: auto;
  position: absolute;
  right: 5px;
  button {
    font-size: 22px;
  }
}
.specialty-title {
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.filter-div {
  width: calc(100% - 116px);
}
.specialty-shared-div {
  text-align: left;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  min-height: 45px;
  box-sizing: border-box;
  @include breakpoint(md) {
    margin-bottom: 0px;
  }
}

.specialty-body-div {
  width: 100%;
  height: auto;
}

.specialty-container {
  margin-top: 5px;
  width: 100%;
  position: absolute;
  .specialty-div {
    background: $white;
    border-radius: 8px;
    z-index: 9;
    position: relative;
    box-shadow: 0px -1px 5px $gray-color-3;
  }
}

.specialty-list {
  width: 100%;
  height: auto;
  padding: 2px 10px;
  margin: 0;
  box-sizing: border-box;
  max-height: 380px;
  overflow: auto;
}
.li-list-specialty {
  .checkbox-input {
    cursor: pointer;
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}

.disabled-filter {
  color: grey !important;
  .checkbox-input {
    label {
      color: grey !important;
      cursor: not-allowed;
    }
  }
}

.cancel-modal-overlay {
  z-index: 99 !important;
}

.clear-filter-btn {
  background-color: transparent;
  outline: transparent;
  border: transparent;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  color: #000000de;
  padding: 0;
  margin: 0;
}
.search-result-text {
  line-height: 18px;
}

.ReactModalPortal {
  .tutorial-video-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    .modal-container {
      color: $black;
      font-family: 'SofiaPro', sans-serif;
      line-height: 22px;
      padding-top: 5%;
    }
  }
  .tutorial-video-modal {
    width: 100%;
    margin: auto;
    font-size: 16px;
    background-color: $white;
    border-radius: 14px;
    position: relative;
    @include breakpoint(md) {
      width: 80%;
    }
    @include breakpoint(lg) {
      width: 60%;
    }
    .ifrme-top {
      position: relative;
      padding-bottom: calc(57.013888888888886% + 40px);
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: #0d3f3c;
      font-size: 24px;
    }
    .video-title {
      font-size: 24px;
      color: $primary-color;
      font-weight: 400;
      line-height: 30px;
    }
    ul {
      list-style-type: disc;
      padding-left: 12px;
      li {
        margin-bottom: 5px;
      }
    }
    iframe {
      width: 100%;
    }
    .video-bg {
      background-color: $white;
      background-image: url('../images/video-bg.png');
      background-position: 100% 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      .video-bg-title {
        font-size: 28px;
        color: $primary-color;
        font-family: 'SofiaPro', sans-serif;
        font-weight: 400;
        height: 100%;
        line-height: 39.31px;
        text-align: left;
      }
    }
    a {
      color: rgba(0, 87, 238, 1);
      &:hover,
      &:active {
        color: rgba(0, 87, 238, 1);
      }
    }
  }
}

.hide-chat {
  .helpscout-chat-container,
  #beacon-container {
    display: none;
    @include breakpoint(md) {
      display: block;
    }
  }
}

.order-modal-overlay {
  z-index: 99;
}

.clickable {
  cursor: pointer;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.tooltip-icon {
  position: relative;
  display: inline-block;
}

.tooltip-icon .tooltip-text {
  visibility: hidden;
  width: 205px;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  left: 20px;
}

.tooltip-icon:hover .tooltip-text {
  visibility: visible;
}

.tooltip-icon .tooltip-text::after {
  content: '';
  position: absolute;
  top: 10%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgba(97, 97, 97, 0.9) transparent transparent;
}

.custom-tooltip-container {
  position: relative;
  display: inline-block;
  .custom-tooltipTop-text {
    visibility: hidden;
    background: $custom-toolTip-bg;
    color: $white;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    font-size: 12px;
    line-height: 17px;
  }
  .custom-tooltip-right {
    top: -5px;
    left: 110%;
    width: 275px;
    &:after {
      content: '';
      position: absolute;
      top: 25%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent rgba(97, 97, 97, 0.9) transparent transparent;
    }
  }
  &:hover .custom-tooltipTop-text {
    visibility: visible;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
  .pointer-events-none {
    pointer-events: none;
  }
}

.space-no-wrap {
  white-space: nowrap;
}

.dashboard-btn-container {
  button,
  a {
    width: 100% !important;
  }
  .icon-button {
    span {
      font-size: 14px;
      @include breakpoint(lg) {
        font-size: 12px;
      }
      @include breakpoint(xl) {
        font-size: 16px;
      }
    }
  }
}

.justify-content-end {
  justify-content: flex-end;
}
.bg-white {
  background: $white;
}

.border-none {
  border: 0;
}
