.tooglePanel {
  padding-top: 12.5px;
  padding-bottom: 25px;
}

.tooglePanelMd {
  padding-top: 25px;
}

.patientCarePanelContainer {
  padding-top: 25px;
}

.prePostIndexContainer {
  @media (max-width: 1279px) {
    padding-bottom: 6px;
  }
}

.inputContainer {
  width: 100%;
  @media (min-width: 1012px) {
    width: 100px;
  }
}

.customFormContainer {
  label {
    font-size: '16px';
    color: var(--color-black);
  }
}
.patientRecordFormContainer {
  input {
    margin-right: 0 !important;
    width: 100%;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: var(--color-black-opacity-60) !important;
    @media (min-width: 1012px) {
      width: 48px !important;
    }
  }
}
