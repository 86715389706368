.onboarding-modal-overlay {
  .modal {
    .gradient-btn {
      position: relative;
      bottom: 0;
      left: 0;
    }
    .modal-container {
      padding-top: 2%;
      .onboarding-modal {
        position: relative;
        background-color: $white;
        width: 100%;
        margin: 0 auto;
        color: $black;
        text-align: left;
        position: relative;
        border-radius: 16px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        font-size: 16px;
        @include breakpoint(md) {
          width: 62%;
        }
        .back-btn {
          background-color: transparent;
          border-radius: 16px;
          outline: none;
          border: 1px solid $primary-color;
          cursor: pointer;
          font-weight: 600;
          font-size: 14px;
        }
        .inputText {
          border: 1px solid rgba(0, 0, 0, 0.5);
          border-radius: 4px;
        }
        .existing-user-text {
          color: #031B1A;
          font-weight: 400;
          font-size: 18px;
          line-height: 30px
        }
        .header-number {
          font-weight: 400;
          font-size: 16px;
          line-height: 40px;
          letter-spacing: 0.5px;
          color: #315c59;
        }
        .onboarding-section-header {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: $black;
        }
        .onboarding-error-container {
          color: #b70e0e;
          position: relative;
          padding: 24px;
          background: #fddfdf;
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
          border-radius: 16px;
          width: 100%;
          font-size: 18px;
          svg {
            font-size: 26px;
          }
        }

        .onboarding-header {
          div {
            height: 8px;
            background: #e2e8e8;
            border-radius: 2px;
            width: 33%;
          }
          .active-header {
            background: $primary-color;
          }
        }
        .two-step {
          div {
            width: 49.6%;
          }
        }
        .input-label {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $black;
        }
        .specialty-container {
          .specialty-list {
            max-height: 265px;
          }
        }
        .specialty-shared {
          border-radius: 4px;
          border-color: rgba(0, 0, 0, 0.5);
          padding: 13px 18px;
          .dropdown-placeholder {
            color: #808080;
          }
          .specialty-down-arrow {
            right: 8px;
            button {
              font-size: 17px;
              color: hsl(0, 0%, 80%);
            }
          }
          .specialty-down-close-arrow {
            position: absolute;
            right: 38px;
            button {
              font-size: 17px;
              color: hsl(0, 0%, 80%);
              border: none;
              background-color: transparent;
              display: flex;
              cursor: pointer;
            }
          }
        }
        .width-50 {
          width: 50%;
          @include breakpoint(lg) {
            width: auto;
          }
        }
        .width-100 {
          width: 100%;
          @include breakpoint(lg) {
            width: auto;
          }
        }
        .fixed-height {
          height: auto;
          @include breakpoint(lg) {
            height: 52px;
          }
        }
      }
      .onboading-bg {
        height: 15px;
        width: 100%;
        margin: 0 auto;
        background-color: $primary-color;
        background-image: url('../images/modal-background.png');
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        background-size: 100% 100%;
        background-position: center center;
        @include breakpoint(md) {
          width: 62%;
        }
      }
      .pricing-label {
        font-size: 14px;
        border: 2px solid #dcdcdc;
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
        display: block;
        cursor: pointer;
        @include breakpoint(lg) {
          width: 32%;
        }
        .input-box {
          background: #e9f5f2;
          border-radius: 16px;
          min-height: auto;
          @include breakpoint(lg) {
            min-height: 185px;
          }
          input[type='radio'] {
            width: 24px;
            height: 24px;
            margin: 0 8px 0 0;
          }
        }
        ul {
          margin: 0;
          li {
            margin-top: 15px;
            span,
            .check-icon {
              background-color: #d8eeee;
              border-radius: 50px;
              display: inline-block;
              width: 20px;
              height: 20px;
              text-align: center;
              box-sizing: border-box;
              margin-right: 10px;
              svg {
                display: inherit;
                height: 20px;
                width: 20px;
              }
            }
          }
        }
        .input-name {
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          color: $primary-color;
        }
        .pricing-desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: $primary-color;
        }
        .pricing-value {
          font-weight: 700;
          font-size: 20px;
          line-height: 34px;
          color: $black;
          @include breakpoint(md) {
            font-size: 24px;
          }
        }
      }
      .label-checked {
        border: 2px solid #016f66;
      }
      .custom-tablist {
        border-radius: 22px;
        li {
          padding: 10px;
        }
        .active-tab {
          border-radius: 22px;
        }
      }
      .tos-bg {
        background-color: #e9f5f2;
        border-radius: 16px;
        box-sizing: border-box;
        .tos-container {
          background-color: $white;
          overflow-y: scroll;
          font-size: 16px;
          height: 45vh;
          @include breakpoint(md) {
            height: 50vh;
          }
          ol {
            margin: 0;
            padding-left: 20px;
            li {
              margin-bottom: 10px;
              ol {
                li {
                  &:first-child {
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }
        .tos-error {
          height: 31vh;
          @include breakpoint(md) {
            height: 40vh;
          }
        }
        .tos-switch {
          .react-switch-handle {
            border: 1px solid $black !important;
            box-shadow: none !important;
          }
          .react-switch-bg {
            border: 1px solid $black;
          }
        }
      }
      .pricing-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $black;
        a {
          color: $black;
        }
      }
    }
  }
}
