@mixin button {
  cursor: pointer;
  font-family: 'SofiaPro', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

// primary button
.btn-primary {
  @include button;
  border: none;
  color: $white;
  background: $gradient-background;
  border-radius: 10px;
  width: 100%;
  padding: 17px;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    box-shadow: 0px 0px 2px 2px #0065ff;
  }
}

// disabled button
.btn-disabled {
  @include button;
  background: $gradient-background;
  opacity: 0.5;
  cursor: not-allowed !important;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    box-shadow: none;
  }
}
.outline-btn-disabled {
  @include button;
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed !important;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    box-shadow: none;
  }
}

//primary link
.link {
  @include button;
  color: $primary-color;
  background: none;
  border: none;
}

//secondary link
.secondary-link {
  @include button;
  background-color: #f0f1f7;

  border: none;
  border-radius: 4px;
  color: $primary-color;

  &:hover {
    background-color: #e1e3ee;
  }
}

//gradient button
.gradient-btn {
  background: $gradient-background;
  border-radius: 16px;
  font-family: 'SofiaPro', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $white;
  border: none;
  cursor: pointer;
}

//order prognostorm button
.order-btn {
  border: 1px solid $primary-color;
  border-radius: 10px;
  background-color: $white;
  padding: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  width: 100%;
  cursor: pointer;
  color: $black;
  @include breakpoint(md) {
    width: 43%;
  }
  @include breakpoint(lg) {
    width: 34%;
  }
  &:hover:not(:disabled) {
    background: $gradient-background;
    color: $white;
  }
}

// secondary button
.btn-secondary {
  @include button;
  border: 1px solid $primary-color;
  border-radius: 10px;
  background-color: $white;

  &:hover {
    background-color: #eef4fd;
  }
  &:active {
    border: 1px solid transparent;
    box-shadow: 0px 0px 2px 3px rgba(90, 152, 242, 0.2);
  }
}

//sort simple button
.sort-btn {
  border: none;
  outline: none;
  background-color: #e5e5e5;
  color: $black;
  padding: 14px 5px;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #262626;
    display: inline-block;
    margin-left: 5px;
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: bold;
  }
}

.blue-btn {
  @include button;
  color: $primary-color;
  background: $white;
  border-radius: 10px;
  border: 1px solid $primary-color;
  padding: 13px 20px;
  width: 100%;
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  &:hover {
    color: $white;
    background: $gradient-background;
  }
  @include breakpoint(md) {
    width: auto;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.blue-btn.btn-disabled {
  background: transparent !important;
  color: $primary-color !important;
}

.gray-btn {
  @include button;
  background-color: $gray-btn-color;
  border-color: transparent;
  border-width: 1px;
  border-radius: 10px;
  color: $white;
  padding: 10px 12px;
  &:hover {
    background-color: transparent;
    border-color: $gray-btn-color;
    color: $gray-btn-color;
  }
}

.security-btn {
  @include button;
  border: 1px solid $gray-btn-color;
  background-color: transparent;
  border-radius: 10px;
  padding: 10px 12px;
  color: $gray-btn-color;
}

.security-btn.btn-disabled {
  background: transparent;
}

.clear-btn {
  @include button;
  background: transparent;
  border: none;
  outline: none;
  top: 18%;
  font-size: 20px;
  left: 0%;
  color: $text-secondary-color;
  cursor: pointer;
  right: 0;
  @include breakpoint(md) {
    top: 22%;
    font-size: 20px;
    left: 0%;
    right: 0;
  }
  @include breakpoint(lg) {
    top: 22%;
    font-size: 20px;
    left: -19%;
    right: unset;
  }
}

.cancel-btn {
  @include button;
  background: #666666;
  border: none;
  outline: none;
  color: $white;
  cursor: pointer;
  padding: 17px;
  border-radius: 10px;
  line-height: 20px;
  width: 100%;
  max-width: 100%;
  @include breakpoint(md) {
    width: 48%;
    max-width: 48%;
  }
}

.plain-btn {
  @include button;
  background: none;
  border: none;
  outline: none;
  color: $primary-color;
}

.link-btn {
  padding: 0;
  font-size: 14px;
}
.add-margin-to-button {
  margin-left: 2px;
  margin-right: 2px;
}
.specialty-down-arrow {
  button {
    font-size: 18px;
    color: $primary-color;
    cursor: pointer;
    border: none;
    background-color: transparent;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: right;
    width: 100%;
    height: 100%;
  }
}

.signin-btn {
  padding: 10px 30px;
  border-radius: 10px;
}
