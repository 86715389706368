.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    position: absolute;
    color: #fff;
    padding: 5px;
    top: calc(100% + 5px);
    background-color: rgba(97, 97, 97, 0.9);
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    z-index: 10;
    right: -5px;
    width: 205px;
    line-height: 16px;
    &:after {
      content: '';
      position: absolute;
      top: -10px;
      right: 4%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent rgba(97, 97, 97, 0.9) transparent;
    }
  }
  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}
