$white: #fff;
$black: #000;

$primary-color: #0d3f3c;
$secondary-color: #0d3f3c;
$highlight-color: #355ff3;

$text-primary-color: #202224;
$text-secondary-color: #767885;
$text-tertiary-color: #979797;
$text-blue-color: #0155d2;
$text-light-color: #ecf3fc;
$text-light-color-1: #869f9e;
$text-light-color-2: #898a8d;

$success-color: #73bc16;
$success-color-1: #016f66;
$active: #016f66;
$warning-color: #e5b50a;
$error-color: #db0028;
$error-color-1: #bf6a67;
$error-bg: #fff2f0;
$error-border: #ffccc7;
$info-color: #4db8e7;

$primary-dark-color: #33559e;
$primary-light-color: #aabbe0;

$secondary-dark-color: #0a5cd2;
$secondary-light-color: #a9c8f5;

$gray-color-1: #f7f7f7;
$gray-color-2: #f8f8fa;
$gray-color-3: #797a7c;
$gray-btn-color: #666666;

$background-light-color-1: #d3ffcf;
$background-light-color-2: #fecfff;
$background-light-color-3: #cfeeff;
$background-light-color-4: #ecf3fc;
$notification-background-color: #ff5530;

$placeholder-color: #8a8a8d;
$bg-secondary: #efefef;
$bg-secondary-1: #010202;
$bg-2: #091e420f;
$bg-3: #d8eeee;

$tag-secondary-color: #cfe8ff;
$custom-toolTip-bg: rgba(97, 97, 97, 0.9);
$custom-toolTip-icon-bg: rgba(97, 97, 97, 0.9);
$banner-link: #8db989;

$form-header-color-primary: #292d32;

//gradient background
$gradient-background: linear-gradient(85.92deg, #0d3f3c 0%, #1e6763 100%);

// hr
$hr-bg: #658583;

// border
$border-type-1: #d1d2d5;
$border-input-primary: #658583;

$gray-700: #464f60;

//color classes
.color-white {
  color: $white !important;
}
.color-black {
  color: $black !important;
}
.color-primary {
  color: $primary-color !important;
}
.color-secondary {
  color: $secondary-color !important;
}
.color-highlight {
  color: $highlight-color !important;
}
.color-text-primary {
  color: $text-primary-color !important;
}
.color-text-secondary {
  color: $text-secondary-color !important;
}
.color-text-light {
  color: $text-light-color !important;
}
.color-success {
  color: $success-color !important;
}
.color-warning {
  color: $warning-color !important;
}
.color-error {
  color: $error-color !important;
}
.color-info {
  color: $info-color !important;
}
.color-primary-dark {
  color: $primary-dark-color !important;
}
.color-primary-light {
  color: $primary-light-color !important;
}
.color-secondary-dark {
  color: $secondary-dark-color !important;
}
.color-secondary-light {
  color: $secondary-light-color !important;
}
.color-gray-1 {
  color: $gray-color-1 !important;
}
.color-gray-2 {
  color: $gray-color-2 !important;
}

//background-color classes
.bg-white {
  background-color: $white !important;
}
.bg-black {
  background-color: $black !important;
}
.bg-primary {
  background-color: $primary-color !important;
}
.bg-secondary {
  background-color: $secondary-color !important;
}
.bg-highlight {
  background-color: $highlight-color !important;
}
.bg-text-primary {
  background-color: $text-primary-color !important;
}
.bg-text-secondary {
  background-color: $text-secondary-color !important;
}
.bg-text-light {
  background-color: $text-light-color !important;
}
.bg-success {
  background-color: $success-color !important;
}
.bg-warning {
  background-color: $warning-color !important;
}
.bg-error {
  background-color: $error-color !important;
}
.bg-info {
  background-color: $info-color !important;
}
.bg-primary-dark {
  background-color: $primary-dark-color !important;
}
.bg-primary-light {
  background-color: $primary-light-color !important;
}
.bg-secondary-dark {
  background-color: $secondary-dark-color !important;
}
.bg-secondary-light {
  background-color: $secondary-light-color !important;
}
.bg-gray-1 {
  background-color: $gray-color-1 !important;
}
.bg-gray-2 {
  background-color: $gray-color-2 !important;
}

//border-color classes
.border-error {
  border-color: $error-color !important;
  &.form-input:not(:focus-within) {
    ::placeholder {
      color: $error-color;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: $error-color;
    }

    ::-ms-input-placeholder {
      color: $error-color;
    }
  }
}
.border-success {
  border-color: $success-color !important;
}
.border-warning {
  border-color: $warning-color !important;
}
.border-info {
  border-color: $info-color !important;
}

.tagPrognosis {
  background-color: $background-light-color-1;
}
.tagDiagnosis {
  background-color: $background-light-color-3;
}
.tagTreatement {
  background-color: $background-light-color-2;
}
