
.custom-tabs {
  .custom-tablist {
    background-color: $gray-color-1;
    padding-inline-start: 0;
    color: $text-primary-color;
    border-radius: 16px;
    width: 100%;
    margin: 0;
    @include breakpoint(md) {
      width: 48%;
    }
    @include breakpoint(lg) {
      width: 42%;
    }
    .active-tab {
      border-radius: 16px;
      color: $white;
    }
    .react-tabs__tab {
      flex: 1;
      text-align: center;
      outline: none;
      box-shadow: none;
    }
    li {
      padding: 12px;
      @include breakpoint(md) {
        padding: 14px;
      }
    }
  }
}
