.template-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(249, 244, 231, 1);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ddd;
    z-index: 9999999;

    &.hidden {
        display: none;
    }

    .banner-content {
        margin: 16px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .banner-title {
        h4 {
            font-size: 18px !important;
            font-weight: 700 !important;
            line-height: 0;
            letter-spacing: 0.005em;
            text-align: left;
            text-underline-position: from-font;
            margin-bottom: 20px;
        }

        p {
            margin: 10px 0 0;
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 24px;
            letter-spacing: 0.005em;
            text-align: left;
            text-underline-position: from-font;
            color: rgba(107, 114, 128, 1);
        }
    }

    .banner-buttons {
        display: flex;
    }

    .banner-btn {
        padding: 10px 15px;
        margin-right: 10px;
        border: none;
        background-color: rgba(240, 227, 195, 0.72);
        color: rgba(186, 136, 34, 1);
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 8px;
      
        svg.forward-arrow {
          width: 20px;
          height: 20px;
          stroke: currentColor;
          transition: transform 0.2s ease;
      
          &:hover {
            transform: translateX(2px);
          }
        }
    }

    .banner-btn-secondary {
        padding: 10px 12px;
        margin-right: 10px;
        border: none;
        color: rgba(186, 136, 34, 1);
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px !important;
        font-weight: 700 !important;
        letter-spacing: 0.005em;
        text-align: center;
        background-color: transparent;
        text-decoration: none;
    }

    .banner-close {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
        color: rgba(156, 163, 175, 1);
        top: 0;
        position: absolute;
        right: 16px;
    }
}

// Extra styles for mobile
@media screen and (max-width: 768px) {
    .template-banner {
        margin: 8px !important;
    }
    .banner-buttons {
        display: flex;
        margin-top: 16px;
    }

    .banner-content {
        margin: 16px;
        width: 100%;
        display: block !important;
    }
}