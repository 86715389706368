.account-container {
  position: relative;
  .account-page {
    width: 100%;
    margin: 0 auto;
    @include breakpoint(md) {
      width: 80%;
    }
    .profile-back-button {
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 24px;
      cursor: pointer;
      padding-left: 0;
      color: $black;
      @include breakpoint(md) {
        top: 4px;
        position: absolute;
        left: 0;
      }
    }
    .avatar {
      cursor: default;
      position: relative;
      img {
        width: 96px;
        height: 96px;
      }
    }
    .input-conatainer {
      width: auto;
      .input-label {
        margin-bottom: 5px;
      }
    }
    .inputText,
    input {
      border: 1px solid $gray-btn-color;
      border-radius: 8px;
      padding: 15px 16px;
      box-sizing: border-box;
      &:focus {
        outline: 1px solid $primary-color;
        border-color: $primary-color;
      }
    }
    input {
      width: 100%;
      font-size: 16px;
      &:focus {
        outline: 1px solid $primary-color;
        border-color: $primary-color;
      }
    }
    .PhoneInput {
      border: 1px solid $gray-btn-color;
      border-radius: 8px;
      padding: 15px 16px;
      .PhoneInputInput {
        border: none;
        padding: 0;
        outline: none;
      }
    }
    .edit-profile {
      color: $black;
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 16px;
      border-color: transparent;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      padding: 0;
    }
    .upload-btn-wrapper {
      overflow: hidden;
      display: inline-block;
      position: absolute;
      bottom: 2px;
      left: 65px;
      width: 40px;
      height: 40px;
      background: $white;
      border: 2px solid $primary-color;
      border-radius: 50%;
      box-sizing: border-box;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .upload-btn-wrapper input[type='file'] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      height: 40px;
      width: 40px;
    }
  }
  .profile-btn {
    padding: 12px 17px;
    font-size: 16px;
    width: 100%;
    line-height: 20px;
    @include breakpoint(md) {
      width: auto;
    }
  }
}
.modal-container {
  .email-modal {
    background-color: $white;
    width: 100%;
    margin: 0 auto;
    color: $black;
    text-align: left;
    position: relative;
    border-radius: 6px;
    @include breakpoint(md) {
      width: 49.5%;
    }
    .input-conatainer {
      width: auto;
      input {
        border: 2px solid $primary-color;
        border-radius: 8px;
        padding: 15px 16px;
        box-sizing: border-box;
      }
    }
    .verification {
      font-size: 12px;
      color: $gray-btn-color;
    }
    .close-btn {
      position: absolute;
      right: 7px;
      top: 22px;
      color: $primary-color;
      background-color: transparent;
      border: none;
      font-size: 24px;
      cursor: pointer;
      @include breakpoint(md) {
        right: 32px;
        top: 32px;
      }
    }
  }
  .order-modal {
    width: 100%;
    @include breakpoint(md) {
      width: 55.5%;
    }
    .close-btn {
      background-color: grey;
      padding: 0px;
      right: 16px;
      top: 16px;
      height: 30px;
      width: 29px;
      @include breakpoint(md) {
        right: 32px;
        top: 32px;
      }
    }
    .error-container {
      font-size: 14px;
      width: 82%;
      @include breakpoint(md) {
        width: 84%;
      }
      a {
        color: #DB0028;
      }
    }
  }
  .crop-image-modal {
    background-color: $white;
    width: 100%;
    margin: 0 auto;
    color: $black;
    text-align: left;
    position: relative;
    @include breakpoint(md) {
      width: 49.5%;
    }
    .close-btn {
      position: absolute;
      right: 32px;
      top: 32px;
      color: $primary-color;
      background-color: transparent;
      border: none;
      font-size: 24px;
      cursor: pointer;
    }
  }
}

.pending-status {
  background-color: #d8eeee;
  border-radius: 8px;
  padding: 6px 10px;
  margin-right: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  color: $black;
}
button.pending-status {
  cursor: pointer;
}

.profile-sub-header {
  font-size: 20px;
  font-weight: bold;
  color: $black;
}

.cancel-email-btn {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  color: $black;
}

.otp-input {
  padding: 10px 6px;
  font-size: 18px;
  @include breakpoint(md) {
    padding: 14px 10px;
  }
}
.otp-seperator {
  width: 5px;
  @include breakpoint(md) {
    width: 10px;
  }
}

.visibility-hidden {
  visibility: hidden;
}
.resend-link {
  border: none;
  background: none;
  outline: none;
  color: $primary-color;
  cursor: pointer;
  font-size: 16px;
}

.PhoneInputCountrySelectArrow {
  border-top: 7px solid rgba(97, 97, 97, 0.9) !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  transform: none !important;
  border-bottom: none !important;
  opacity: 1 !important;
}

.crop-image-header {
  font-size: 22px;
  font-weight: bold;
  color: $black;
}
.crop-image {
  width: 100%;
  margin: 0 auto;
  @include breakpoint(md) {
    width: 50%;
  }
  @include breakpoint(lg) {
    width: 50%;
  }
  @include breakpoint(xl) {
    width: 50%;
  }
}
