@import 'svg';

.img {
  background-repeat: no-repeat;
  &.bell {
    background-image: $bellIcon;
    width: 34.875px;
    height: 41.002px;
  }
  &.notification-success {
    background-image: $notificationSuccess;
    width: 30px;
    height: 30px;
  }
  &.notification-failure {
    background-image: $notificationFailure;
    width: 30px;
    height: 30px;
  }

  &.size-16 {
    height: 16px;
    width: 16px;

    &.check-mark {
      background-image: $check-mark;
    }
    &.up-down-arrow {
      background-image: $up-down-arrow;
    }
  }
}
