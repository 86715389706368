h1,
.h1 {
  font-size: 36px !important;
  font-weight: 600 !important;
}

h2,
.h2 {
  font-size: 32px !important;
  font-weight: 600 !important;
}

h3,
.h3 {
  font-size: 28px !important;
  font-weight: 600 !important;
}

h4,
.h4 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

h5,
.h5 {
  font-size: 20px !important;
  font-weight: 600 !important;
}

h6,
.h6 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

p,
.p {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.small {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.fw-light {
  font-weight: 300 !important;
}
.fw-regular {
  font-weight: 400 !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.text-capitalize {
  text-transform: capitalize;
}

.txt {
  color: $text-primary-color;
  font-weight: 400;
  // font-sizes
  &.size-10 {
    font-size: 10px;
  }
  &.size-12 {
    font-size: 12px;
  }
  &.size-13 {
    font-size: 13px;
  }
  &.size-14 {
    font-size: 14px;
  }
  &.size-15 {
    font-size: 15px;
  }
  &.size-16 {
    font-size: 16px;
  }
  &.size-18 {
    font-size: 18px;
  }
  &.size-20 {
    font-size: 20px;
  }
  &.size-24 {
    font-size: 24px;
  }
  // font-weight
  &.w-500 {
    font-weight: 500;
  }
  &.w-600 {
    font-weight: 600;
  }
  &.w-700 {
    font-weight: 700;
  }
  // font-colors
  &.form-input-header {
    color: $form-header-color-primary;
  }
  &.success {
    color: $success-color-1;
  }
  &.dark {
    color: var(--color-dark);
  }
  &.tertiary {
    color: $text-tertiary-color;
  }
  &.error {
    color: $error-color-1;
  }
  &.primary-1 {
    color: $gray-700;
  }
  &.text-color-light {
    color: $text-light-color-1;
  }
  &.text-color-light-2 {
    color: $text-light-color-2;
  }
  &.gray-10 {
    color: #444654;
  }
  &.gray-20 {
    color: #343541;
  }
  &.text-danger {
    color: var(--color-error);
  }
  &.gray-80 {
    color: var(--color-gray-80);
  }
  &.text-black-opacity-87 {
    color: var(--color-black-opacity-87);
  }
  &.text-black-800 {
    color: var(--color-neutral-800);
  }
  &.text-black-900 {
    color: var(--color-neutral-900);
  }
  &.text-gray-80 {
    color: var(--color-gray-80);
  }
  &.text-gray-700 {
    color: var(--color-Neutral-700);
  }
  &.text-gray-dark {
    color: var(--color-gray-dark);
  }
  &.text-primary-1 {
    color: var(--color-primary-1);
  }
  &.text-slate-gray {
    color: var(--color-slate-gray);
  }

  // font-line-height
  &.lh-17 {
    line-height: 17px;
  }
  &.lh-19 {
    line-height: 19px;
  }
  &.lh-20 {
    line-height: 20px;
  }
  &.lh-21 {
    line-height: 21px;
  }
  &.lh-22 {
    line-height: 22px;
  }
  &.lh-24 {
    line-height: 24px;
  }
  &.lh-28 {
    line-height: 28px;
  }
  &.lh-32 {
    line-height: 32px;
  }
  &.lh-40 {
    line-height: 40px;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  // text-decoration
  &.underline {
    text-decoration: underline;
  }
  //font-family
  &.font-inter {
    font-family: 'Inter', sans-serif;
  }
}
