.dataSourceTableContainer {
  display: block;
  width: 100%;
  overflow-x: auto;
  border: 1px solid var(--border-neutral-200);
  border-radius: 8px;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    font-size: 14.5px;

    th {
      background-color: var(--bg-gray-100);
      text-align: left;
      padding: 20px 12px;
      text-transform: uppercase;
    }

    td {
      padding: 11px 11px 11px 20px;
      border-top: 1px solid var(--border-neutral-200);
      background-color: var(--bg-white);
      text-align: left;
    }
    thead {
      tr {
        th:first-child {
          padding-left: 2.7rem;
        }
      }
    }
    tbody {
      tr.leftspace {
        td {
          padding-left: 2.7rem;
        }
      }
    }
  }
  .grouphead {
    background-color: var(--bg-gray-50);
  }
}
