.snackbar-container {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $bg-secondary-1;
  padding: 12px 5%;

  @include breakpoint(sm) {
    padding: 15px;
  }

  .snackbar-wrapper {
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    @include breakpoint(md) {
      padding-right: 75px;
      width: 95%;
      margin-left: auto;
    }

    @include breakpoint(lg) {
      width: 85%;
    }

    @include breakpoint(xl) {
      width: 75%;
    }
  }

  .snackbar-message {
    color: $white;
    font-size: 12.5px;
    font-style: normal;
    font-weight: 500;

    @include breakpoint(md) {
      font-size: 15px;
    }

    a {
      @extend .banner-link;
    }
  }

  .snackbar-img {
    max-width: 100%;
    height: 30px;
  }
}

.banner-link {
  color: $banner-link;
  text-decoration: none;
  border-bottom: 1px solid $banner-link !important;
}

.bell-icon-img {
  margin-right: 15px;
  position: relative;
}

.notification-dropdown-container {
  hr {
    border-top: 0.5px solid $hr-bg;
    opacity: 0.4;
  }

  .not-read {
    background-color: $bg-2;
  }

  .empty-notifications {
    height: 150px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notification-body {
    padding: 7px 5px;
    display: flex;
    gap: 5px;

    p {
      margin: 0 !important;
    }

    img {
      max-width: 100%;
      height: 20px;
      position: relative;
      top: 2.5px;
    }

    a {
      @extend .banner-link;
      padding-bottom: 0 !important;
      cursor: pointer;
    }

    div {
      font-size: 13px;
    }
  }

  .unread-count {
    height: 18px;
    width: 18px;
    background: $notification-background-color;
    font-size: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    color: $white;
  }

  .notification-wrapper {
    margin: 0 5px;
  }

  .h-300 {
    height: 300px;
  }
}

.notification-header {
  padding: 15px;

  @include breakpoint(md) {
    padding: 15px 22px 10px 28px;
  }
}

.notifications-dropdowncontainer {
  position: absolute;
  right: -85px;
  width: 300px;
  top: 50px;
  background: #fff;
  border-radius: 8px;
  z-index: 15;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  #scrollableDiv {
    padding: 15px;

    @include breakpoint(md) {
      padding: 15px 22px 15px 28px;
    }

    @include breakpoint(lg) {
      max-height: 450px !important;
    }
  }
  @media screen and (min-width: 375px) {
    width: 350px;
  }
  @media screen and (min-width: 400px) {
    width: 375px;
  }
  @media screen and (min-width: 500px) {
    right: 0;
  }
}