@import 'assets/scss/colors.scss';

.customTabList {
  border-bottom: 1px solid #aaa;
  margin: 0;

  margin-bottom: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 4px;
  }

  &:hover {
    scroll-snap-type: x mandatory;
  }
}
.activeTab {
  background: #fff;
  border-color: $primary-color;
  border-radius: 5px 5px 0 0;
}

.activeRow {
  background-color: #e6e9eb;
  transition: background-color 4000ms linear;

  td {
    border-bottom: 0;
    background-color: #e6e9eb;
  }
}
.emptyTablerows {
  height: 10px;
  padding: 0 !important;
  border: 0 !important;
  background: #d8eeee !important;
}
.paginationRow {
  height: 10px;
  padding: 0 !important;
  border: 0 !important;
}
.noneBorderBottom {
  border-bottom: 0 !important;
}
.tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}
